export const pastParticipantsHeaders = [
  'Full Name',
  'Session Type',
  'Device',
  'IP Address',
  'Final State',
  'Join Time',
  'Established Time',
  'Ended Time',
  'Duration',
  'App Version',
  'Used Audio',
  'Used Video',
  'Used Screen',
  'Call Rating',
  'Force Terminate',
];

export const singleParticipantHeaders = [
  'Full Name',
  'Device',
  'Serial Number',
  'State',
  'IP Address',
  'Join Time',
  'App Version',
  'Used Audio',
  'Used Video',
  'Used Screen',
  'Browser',
  'Object ID',
  'Company Role',
];

export const peerParticipantsHeaders = ['Full Name', 'Device', 'State', 'Connected at'];
